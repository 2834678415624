import PropTypes from "prop-types"
import { serviceShape } from "app/lib/shared_prop_types"

export default function ServiceCard(props) {
  const { service } = props
  const backgroundColor = service.brand_color.startsWith("#") ? service.brand_color : `#${service.brand_color}`

  // Any change on this structure should be reflected on Rails partial shared/cards/_service.html.erb.
  return (
    <a
      className={`service-card ${props.extraClass}`}
      style={{ backgroundColor }}
      onClick={props.onClick}
      href={props.path}
    >
      <img
        src={service.monochrome_image_url}
        title={service.name}
        alt="" // alt is considered redundant when there is an adjacent text with same content.
        loading={props.lazyLoad ? "lazy" : "eager"}
        width="54"
        height="54"
        className="service-card-image"
      />
      <span className="service-card-title">{service.name}</span>
    </a>
  )
}

ServiceCard.propTypes = {
  service: serviceShape.isRequired,
  onClick: PropTypes.func,
  path: PropTypes.string,
  extraClass: PropTypes.string,
  lazyLoad: PropTypes.bool,
}

ServiceCard.defaultProps = {
  extraClass: "",
  lazyLoad: true,
  service: {},
}
