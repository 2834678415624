import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classname from "classnames"
import { capitalize } from "humanize-plus"

import GetMoreView from "./get_more_view/index"
import SearchView from "./search_view/index"
import ServiceView from "./service_view/index"
import SearchInput from "./../shared/search_input"
import debounce from "lodash/debounce"

import { serviceShape } from "app/lib/shared_prop_types"

import { toggleView, setOrigin } from "./reducers/view_toggle"
import { fetchSearchResults, setSearchTerm } from "./reducers/search_results"
import { fetchBelowFoldDiscoverContent } from "./reducers/get_more_results"
import { fetchAllServices } from "./reducers/service_results"

class GetMoreLandingPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: this.props.currentView,
      hideFilter: false,
      historyAction: "pushState",
      showServiceSuggestion: false,
      searchCompleted: false,
    }

    if (this.props.searchTerm.length) {
      this.props.toggleView("search")
    }

    this.debouncedSearch = debounce(this.search, 250)
    this.debouncedHandleScrollToBottom = debounce(this.handleScrollToBottom, 150)
    this.debouncedLogSearchCompleted = debounce(this.logSearchCompleted, 250, { leading: true })
  }

  componentDidMount() {
    this.preloadRecommendedServiceIcons(this.props.recommendedServices.map(service => service.monochrome_image_url))
    // Load data only once, when wrapper component is mounted. Inside GetMoreView, it fires everytime user switches between Search and Discover.
    this.props.fetchBelowFoldDiscoverContent()
    if (this.props.allServices.length === 0) {
      this.props.fetchAllServices()
    }

    window.addEventListener("scroll", this.debouncedHandleScrollToBottom)
    window.addEventListener("scroll", this.debouncedLogSearchCompleted)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.debouncedHandleScrollToBottom)
    window.removeEventListener("scroll", this.debouncedLogSearchCompleted)
  }

  preloadRecommendedServiceIcons = urls => {
    urls.forEach(url => {
      const link = document.createElement("link")
      link.rel = "preload"
      link.as = "image"
      link.href = url
      document.head.appendChild(link)
    })
  }

  logSearchCompleted = () => {
    if (this.props.searchTerm?.length && !this.state.searchCompleted) {
      window.App.Utils?.logCustomDatadogAction?.("explore_search_completed", {
        searchTerm: this.props.searchTerm,
        currentView: this.props.currentView,
      })

      this.setState((state, props) => ({ searchCompleted: true }))
    }
  }

  handleScrollToBottom = () => {
    const scrollTop = window.scrollY
    const documentHeight = document.documentElement.scrollHeight
    const windowHeight = window.innerHeight

    if (this.scrollTimeout) clearTimeout(this.scrollTimeout)

    if (scrollTop + windowHeight >= documentHeight) {
      const currentView = this.props.currentView
      const searchTerm = this.props.searchTerm

      this.scrollTimeout = setTimeout(() => {
        window.App.Utils?.logCustomDatadogAction?.("get_more_scroll_to_bottom", {
          currentView,
          searchTerm,
        })
      }, 1000)
    }
  }

  prettifyTerm = term => {
    return term.trim().replace(/\./g, "")
  }

  onQuerySearch = evt => {
    //Not trimming this here because we want the user to be able to put spaces in the search bar.
    const term = evt.target.value

    // Reset search and back to discover page
    if (!term) {
      this.debouncedSearch.cancel()
      this.onSearchXClick()
      this.setState({ showServiceSuggestion: true }) // user has focus on input
      return
    }

    this.debouncedSearch(term)
  }

  onSuggestedServiceClick = (evt, serviceName) => {
    evt.preventDefault()
    this.search(serviceName)
  }

  search = term => {
    //On the Search page we want the url bar to change to match the query and active tab.
    const url = `/search/query/${term}`
    window.history[this.state.historyAction]({ url }, "", url)

    this.setState({
      historyAction: "replaceState",
      showServiceSuggestion: false,
    })

    if (this.props.currentView !== "search") {
      this.props.toggleView("search")
      this.props.setOrigin("discover")
    }

    const previousTerm = this.props.searchTerm

    //show non-prettified term in the search bar
    this.props.setSearchTerm(term)

    //prettify term and compare to previous term for the actual search query
    const termForSearch = this.prettifyTerm(term)

    if (termForSearch === this.prettifyTerm(previousTerm)) {
      return
    }

    this.props.getSearchResults(termForSearch)
  }

  onSearchInputClick = () => {
    this.setState({
      hideFilter: true,
      showServiceSuggestion: this.props.searchTerm === "",
      searchCompleted: false,
    })

    document.querySelector(".filter-content-list").style.display = "none"

    window.App.Utils?.logCustomDatadogAction?.("explore_click_search_bar")
  }

  onSearchXClick = () => {
    this.setState(
      {
        activeTab:
          this.state.activeTab === "search"
            ? "discover_all" // user lands on page via GET, example: https://ifttt.me/search/query/evernote. Reset search and show discover all view.
            : this.state.activeTab,
      },
      () => {
        // only continues with activeTab updated to avoid race condition.
        this.props.setSearchTerm("")

        //Show discover view and set the origin to search
        this.props.toggleView(this.state.activeTab)
        this.props.setOrigin("search")

        //Change the url bar back to the Get More/Explore page.
        const url = this.buildContentUrl(this.state.activeTab.replace(/^discover_/, ""))
        window.history.pushState({ url }, "", url)
        this.setState({
          historyAction: "pushState",
          searchCompleted: false,
        })

        this.setFilterVisible()
      }
    )
  }

  onSearchBlur = () => {
    // Execute in another thread to allow browser identify click on suggestion list
    setTimeout(() => {
      this.setState({ showServiceSuggestion: false })
    }, 150)

    if (this.props.searchTerm === "") {
      this.setFilterVisible()
    }

    this.logSearchCompleted()
  }

  setFilterVisible = () => {
    document.querySelector(".filter-content-list").style.display = "flex"
    this.setState({ hideFilter: false })
  }

  switchTab = newTab => {
    this.props.toggleView(`discover_${newTab}`)
    this.setState({ activeTab: `discover_${newTab}` })

    const url = this.buildContentUrl(newTab.toLowerCase())
    window.history.replaceState({ url }, "", url)

    const tabTitle = newTab === "all" ? "Integrations" : capitalize(newTab)
    document.title = `Explore ${tabTitle} - IFTTT`

    const ogTitle = document.querySelector('meta[property="og:title"]')
    if (ogTitle) {
      ogTitle.content = `Explore ${tabTitle} - IFTTT`
    }
  }

  buildContentUrl = tab => {
    let url = "/explore"
    if (tab !== "all") {
      url = `/explore/${tab}`
    }

    return url
  }

  viewToRender = () => {
    switch (this.props.currentView) {
      case "search":
        return <SearchView />
      case "discover_services":
        return <ServiceView />
      default:
        return <GetMoreView />
    }
  }

  render() {
    return (
      <div className="get-more-container">
        <div className="get-more-header">
          <h1>Explore</h1>
          <section className="ifttt-next-filter filter-content-container">
            <ul
              role="tablist"
              className={classname("filter-content-list", {
                hide: this.props.currentView === "search" || this.state.hideFilter,
              })}
            >
              {["all", "applets", "services", "stories"].map(tab => (
                <li
                  key={tab}
                  role="tab"
                  onClick={() => this.switchTab(tab)}
                  className={
                    classname({
                      active: this.state.activeTab === `discover_${tab}`,
                    }) + ` link-standalone`
                  }
                >
                  {capitalize(tab)}
                </li>
              ))}
            </ul>
          </section>
          <div className={`ifttt-next-search ${this.props.currentView}`}>
            <SearchInput
              autoComplete="off"
              autoFocus={false}
              maxLength="1024"
              onChange={this.onQuerySearch}
              onClick={this.onSearchInputClick}
              onXClick={this.onSearchXClick}
              onBlur={this.onSearchBlur}
              placeholder="Search Applets or services"
              showCloseIcon={this.props.currentView === "search"}
              value={this.props.searchTerm}
            />
            {this.state.showServiceSuggestion && this.props.allServices && (
              <ul className="autocomplete">
                {this.props.recommendedServices.map((service, idx) => (
                  <li key={idx}>
                    <a onClick={evt => this.onSuggestedServiceClick(evt, service.name)}>
                      <img
                        src={service.monochrome_image_url}
                        alt={service.name}
                        title={service.name}
                        width="36"
                        height="36"
                        loading="lazy"
                        style={{
                          backgroundColor: `#${service.brand_color}`,
                        }}
                      />
                      <span className="service-tile-name">{service.name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {this.viewToRender()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentView: state.viewToggle.currentView,
    searchTerm: state.searchResults.searchTerm,
    allServices: state.serviceResults.allServices,
    recommendedServices: state.searchResults.recommendedServices,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleView: view => dispatch(toggleView(view)),
    setOrigin: view => dispatch(setOrigin(view)),
    getSearchResults: term => dispatch(fetchSearchResults(term)),
    setSearchTerm: term => dispatch(setSearchTerm(term)),
    fetchBelowFoldDiscoverContent: () => dispatch(fetchBelowFoldDiscoverContent()),
    fetchAllServices: () => dispatch(fetchAllServices()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetMoreLandingPage)

export const onClickSearchResult = (actionName, data) => {
  window.App.Utils?.logCustomDatadogAction?.("explore_click_any_result", data)
  window.App.Utils?.logCustomDatadogAction?.(actionName, data)
}

GetMoreLandingPage.propTypes = {
  currentView: PropTypes.string,
  diyPath: PropTypes.string,
  fetchAllServices: PropTypes.func,
  fetchBelowFoldDiscoverContent: PropTypes.func,
  getSearchResults: PropTypes.func,
  searchTerm: PropTypes.string.isRequired,
  allServices: PropTypes.arrayOf(serviceShape).isRequired,
  recommendedServices: PropTypes.arrayOf(serviceShape).isRequired,
  setOrigin: PropTypes.func,
  setSearchTerm: PropTypes.func,
  toggleView: PropTypes.func,
  searchCompleted: PropTypes.bool,
}

GetMoreLandingPage.defaultProps = {
  searchTerm: "",
  searchCompleted: false,
}
