import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Spinner from "shared/components/spinner"
import spinnerConfig from "../utils/spinner_config"
import { removeChars } from "../utils/remove_chars"

import { serviceShape } from "app/lib/shared_prop_types"
import ServiceCard from "shared/components/service_card"

import { setCategoryFilter } from "../reducers/service_results"
import { onClickSearchResult } from "../get_more_landing_page"

export class ServiceView extends Component {
  transformURL = category => {
    return category?.toLowerCase().split(/\W+/).join("-")
  }

  handleCategoryChange = evt => {
    const category = evt.target.value

    this.props.setCategoryFilter(category)

    const url = `/explore/services/${this.transformURL(category)}`
    window.history.pushState({ url }, "", url)
    document.title = `Explore ${category} Services`

    const ogTitle = document.querySelector('meta[property="og:title"]')
    if (ogTitle) {
      ogTitle.content = `Explore ${category} Services`
    }
  }

  allServicesFiltered = () => {
    const category = removeChars(this.props.serviceCategoryFilter)

    switch (category) {
      case "all":
        return this.props.allServices
      case "new":
        return this.props.allServices.filter(s => s.tags.includes("new"))
      case "popular":
        return this.props.allServices.filter(s => s.tags.includes("popular"))
      default:
        return this.props.allServices.filter(s => removeChars(s.category?.name) === category)
    }
  }

  setCategory = () => {
    const selectedCategory = removeChars(this.props.serviceCategoryFilter)

    switch (selectedCategory) {
      case "all":
        return "All"
      case "new":
        return "New"
      case "popular":
        return "Popular"
      default:
        return this.props.allServicesCategories.find(category => {
          return removeChars(category) === selectedCategory
        })
    }
  }
  render() {
    const isFiltered = this.props.serviceCategoryFilter !== "all"

    return (
      <div className={`web-services-container ${this.props.currentView}`}>
        <select className="service-category-filter" onChange={this.handleCategoryChange} value={this.setCategory()}>
          <optgroup label="Filters">
            <option value="All">All services</option>
            <option value="New">New services</option>
            <option value="Popular">Popular services</option>
          </optgroup>
          <optgroup label="Categories">
            {this.props.allServicesCategories.map((category, idx) => {
              return (
                <option key={idx} value={category}>
                  {category}
                </option>
              )
            })}
          </optgroup>
        </select>
        <div className="web-service-cards">
          {this.allServicesFiltered().map((service, idx) => {
            return (
              <div key={idx} className="web-service-card explore-tile">
                <ServiceCard
                  service={service}
                  path={`/${service.module_name}`}
                  lazyLoad={idx > 5} // eager load the first 6
                  onClick={() =>
                    onClickSearchResult(
                      `explore_click_${isFiltered ? "filtered_category_service_result" : "get_more_service"}`,
                      {
                        searchResultType: "service",
                        searchResultId: service.module_name,
                      }
                    )
                  }
                />
              </div>
            )
          })}
        </div>
        {this.props.loading && <Spinner config={spinnerConfig} />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    allServices: state.serviceResults.allServices,
    allServicesCategories: state.serviceResults.allServicesCategories,
    currentView: state.viewToggle.currentView,
    loading: state.serviceResults.loading,
    origin: state.viewToggle.origin,
    serviceCategoryFilter: state.serviceResults.serviceCategoryFilter,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCategoryFilter: view => dispatch(setCategoryFilter(view)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceView)

ServiceView.propTypes = {
  allServices: PropTypes.arrayOf(serviceShape),
  allServicesCategories: PropTypes.array,
  currentView: PropTypes.string,
  loading: PropTypes.bool,
  origin: PropTypes.string,
  serviceCategoryFilter: PropTypes.string,
  setCategoryFilter: PropTypes.func,
}

ServiceView.defaultProps = {
  allServices: [],
  loading: false,
}
